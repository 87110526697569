import React, { useContext } from 'react';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import { Layout } from '../components/Common/';
import { SetLocationContext } from '../providers/LocationProvider';

const NotFound = (props) => {
  const setLocation  = useContext(SetLocationContext);
  setLocation('404');
  return ( 
    <Layout>
      <section style={{ height: '80vh' }} className="flex flex-col justify-center items-center">
        <h1 className="font-bold text-4xl md:text-6xl">
          {<FormattedMessage id="notFound" />}
        </h1>
        <Link to="/" className="mt-6 text-3xl default-link">
          {<FormattedMessage id="homeLink" />}
        </Link>
      </section>
    </Layout>   
  );
}
 
export default NotFound;